<template>
<label class="toggleSwitch">
  <input type="checkbox" v-model="isSwitchedRight" @input="toogle" :disabled="!isAllowInstallment">
  <span :class="[isAllowInstallment ? 'slider' : 'sliderDisabled']" class="round"></span>
</label>
</template>

<script>
export default {
  name: "ToggleSwitch",
  props: {
    isAllowInstallment: {
      type: Boolean,
      default: false
    },
    optionsList: {
      type: Array,
      default: () => [],
    },
    isSwitchedRight: {
      type: Boolean,
      default: true
    },
    defaultPaymentFrequency: {
    type: String,
    default: ''
  }
  },
  data() {
    return {
      checked: true,
    }
  },
  mounted() {
    if(this.isSwitchedRight) {
      this.checked = false;
    }
  },
  methods: {
    toogle() {
      const selectedOption = this.isSwitchedRight ? this.optionsList[1] : this.optionsList[0];
      this.$emit('selected', selectedOption);
      this.$emit('enableAnimate', selectedOption);
  }
  }
}
</script>

<style scoped>
.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  margin-bottom: 0px;
}

.toggleSwitch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSwitch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EF4A24;
  -webkit-transition: .4s;
  transition: .4s;
}
.toggleSwitch .sliderDisabled {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.toggleSwitch .slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  transition: .4s;
}
.toggleSwitch .sliderDisabled:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  right: 2px;
  bottom: 2px;
  background-color: #fff;
  transition: .4s;
  border-radius: 34px;
}

.toggleSwitch input:checked + .slider {
  /* background-color: #EF4A24; */
}

.toggleSwitch input:focus + .slider {
  box-shadow: 0 0 1px #EF4A24;
}

.toggleSwitch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.toggleSwitch .slider.round {
  border-radius: 34px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.toggleSwitch .slider.round:before {
  border-radius: 50%;
}
</style>