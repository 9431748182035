<template>
    <div class="toast">
        <div class="toast-body">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ $t('hasbeenadded') }}
        </div>
    </div>
    <div class="modal modal-mv d-flex justify-content-center align-items-center" v-if="show">
      <div class="modal-dialog modal-dialog-scrollable" :style="'width:' + width +';'" :class="classModal">
        <div class="modal-content" :class="{voucher: voucher}">
            <div class="modal-title">
                <div class="d-flex">
                    <h1 class="title-modal">{{ $t(selector+'Title') }}</h1>
                    <button class="btn btn-close-modal" :class="{voucher: voucher}" @click="close()"></button>
                </div>
            </div> 
            <div class="contentBox desc">
                <div class="desc"><p> {{ $t(selector + 'Desc') }}</p></div>
                <div class="plan-title mb-2"  v-if="product?.name?.value === 'Dengue'">{{ $t('titleDenguePlan') }}</div>
                <div class="plan-title mb-2" v-else>{{ $t('titleChooseSumInsured') }}</div>
                <div v-if="Global?.ciProductToPick?.name?.value !== 'Dengue'">
                    <div class="custom-scroll-modal" >
                        <button class="btn btn-left-modal rounded-circle"  :style="{'bottom': showInvalid ? '90px' : '43px'}" @click="scroll_left(Global?.ciProductToPick.name.value)">
                            <svg
                            fill="#EF4A24"
                            height="12"
                            viewBox="0 0 24 24"
                            role="img"
                            style="transform: rotate(90deg); -webkit-transform: rotate(90deg);"
                            >
                            <path
                                d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                            ></path>
                            </svg> 
                        </button>
                        <button class="btn btn-right-modal rounded-circle" :style="{'bottom': showInvalid ? '90px' : '43px'}"  @click="scroll_right(Global?.ciProductToPick.name.value)">
                            <svg
                            fill="#EF4A24"
                            height="12"
                            viewBox="0 0 24 24"
                            role="img"
                            style="transform: rotate(270deg); -webkit-transform: rotate(270deg);"
                            >
                            <path
                                d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                            ></path>
                            </svg> 
                        </button>
                        
                        <div class="item-wrapper-modal" :id="'custom-scroll-modal'">
                            <div class="item-modal"
                                v-for="(option, index) in Global?.ciProductToPick?.optionList?.option" 
                                v-bind:class="{active : selectedIndex == index }"
                                v-bind:key="Global?.ciProductToPick.name.value+'-option-' + index" 
                                :data-selenium-name="'package-'+Global?.ciProductToPick.name.value" :data-selenium-value="index+1"
                                @click="selectedIndex = index; selectedOption = option; showInvalid = false;"
                            >   
                                <div class="circleContainer" v-if="selectedIndex == index">
                                    <div class="circle-check1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                                            <path d="M4.1665 11.5981L7.49984 14.9315L15.8332 6.59814" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="text-center" v-else>
                                    <div class="black500">{{ hitungPremi(option.premiumBeforeTaxes?.value).isMinus == true ? $t('decreasePremi') : $t('increasePremi') }}</div>
                                    <div class="boxGrey400">
                                        {{ hitungPremi(option.premiumBeforeTaxes?.value).isMinus ? '-' : '+' }}
                                        {{$t('currency')}} {{formatNumber(hitungPremi(option.premiumBeforeTaxes?.value).premi)}}
                                    </div>
                                </div>
                                <div class="text-center">
                                    <div class="black500">{{$t('sumInsured')}}</div>
                                    <div class="black700">{{$t('currency')}} {{ formatDisplaySumInsured(option?.sumAssured?.value).number }} {{ formatDisplaySumInsured(option?.sumAssured?.value).isBio ? $t('bio') : $t('mio')}}</div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 
                <div v-if="Global?.ciProductToPick?.name?.value === 'Dengue'">
                    <div class="custom-scroll-modal" >
                        <button class="btn btn-left-modal rounded-circle" :style="{'bottom': showInvalid ? '90px' : '43px'}" @click="scroll_left(Global?.ciProductToPick.name.value)">
                            <svg
                            fill="#EF4A24"
                            height="12"
                            viewBox="0 0 24 24"
                            role="img"
                            style="transform: rotate(90deg); -webkit-transform: rotate(90deg);"
                            >
                            <path
                                d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                            ></path>
                            </svg> 
                        </button>
                        <button class="btn btn-right-modal rounded-circle" :style="{'bottom': showInvalid ? '90px' : '43px'}" @click="scroll_right(Global?.ciProductToPick.name.value)">
                            <svg
                            fill="#EF4A24"
                            height="12"
                            viewBox="0 0 24 24"
                            role="img"
                            style="transform: rotate(270deg); -webkit-transform: rotate(270deg);"
                            >
                            <path
                                d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                            ></path>
                            </svg> 
                        </button>
                        
                        <div class="item-wrapper-modal" :id="'custom-scroll-modal'">
                            <div class="item-modal dengue"
                                v-for="(option, index) in Global?.ciProductToPick?.optionList?.option" 
                                v-bind:class="{active : selectedIndex == index }"
                                v-bind:key="Global?.ciProductToPick.name.value+'-option-' + index" 
                                :data-selenium-name="'package-'+Global?.ciProductToPick.name.value" :data-selenium-value="index+1"
                                @click="selectedIndex = index; selectedOption = option; showInvalid = false;"
                            >
                                <div class="packageLabel">{{getPackageLabel(Global?.ciProductToPick?.name?.value, index)}}</div>
                                <div class="circle-check1" v-if="selectedIndex == index">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 21" fill="none">
                                        <path d="M4.1665 11.5981L7.49984 14.9315L15.8332 6.59814" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div class="text-center" v-else>
                                    <div class="black500">{{ hitungPremi(option.premiumBeforeTaxes?.value).isMinus == true ? $t('decreasePremi') : $t('increasePremi') }}</div>
                                    <div class="boxGrey400">
                                        {{ hitungPremi(option.premiumBeforeTaxes?.value).isMinus ? '-' : '+' }}
                                        {{$t('currency')}} {{formatNumber(hitungPremi(option.premiumBeforeTaxes?.value).premi)}}
                                    </div>
                                </div>
                                <div style="display: flex; flex-direction: column;">
                                    <div class="black500" style="margin: 0 4px;">{{$t('packageLabel.sumInsuredDengue')}}</div>
                                    <div class="black700">{{ $t('currency') }} {{ formatNumber(option?.sumAssured?.value/1000000) }} {{ $t('mio') }} {{ $t('peryear') }}</div>
                                </div>
                                <span class="black500">
                                    {{ $t('maxCover') }} {{$t('currency')}}{{ formatNumber(option?.coverages?.coverage[1]?.additionalCoverageSumAssured?.value) }}/{{$t('day')}}
                                </span>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div>
            <div class="invalidInput" v-if="showInvalid">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#DA1E28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ $t('invalidInputInfo') }}
            </div>
            <div class="button-center">
                <div class="cancelbutton"  @click="close(); selectedIndex = null;">{{ $t('cancelButton') }}</div>
                <span></span>
                <div class="addbutton"  @click="addCoverage()">{{ $t('addButton') }}</div>
            </div>
        </div>
      </div>
    </div>
    
</template>
    
<script>
import Global from "@/store/global.js";
import Mixin from "@/components/Mixin";

    export default {
        mixins: [Mixin],
        name: "ModalPickCoverage",
        data() {
            return {
                Global,
                selectedIndex: null,
                selectedOption: null,
                show: false,
                showInvalid: false,
            }
        },
        computed:{
            
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            voucher: {
                type: Boolean,
                default: false
            },
            voucherClose: {
                type: Boolean,
                default: false
            },
            width: {
                type: String,
                default: "600px"
            },
            title: {
                type: String,
                default: ""
            },
            desc: {
                type: String,
                default: ""
            },
            classModal: {
                type: String,
                default: ""
            },
            selector: {
                type: String,
                default: "cancer"
            }
        },
        updated() {
            this.itemScroll();
        },
        methods: {
            close(){
                this.$emit("close");
                this.selectedIndex = null;
                this.showInvalid = false;
            },
            formatDisplaySumInsured(nominal){
                var number = 0;
                var isBio = false
                if(nominal >= 1000000000){
                    number = nominal/1000000000
                    isBio = true
                } else {
                    number = nominal/1000000
                }
                return {
                    number: number,
                    isBio: isBio
                }
            },
            addCoverage(){
                if(this.selectedIndex !== null){
                    let option = Global?.ciProductToPick?.optionList?.option[this.selectedIndex]
                    Global.selectedPackages[Global.ciProductToPick.name.value] = option; 
                    this.eventLog(Global.ciProductToPick.name.value, option?.sumAssured?.value);
                    this.selectedIndex = null
                    $('.toast').toast('show');
                    this.close(); 
                    this.$emit("reanimate");
                } else {
                    this.showInvalid = true;
                }
            },
            getPriceByProductOptionPremi(_product, _option){
                if(Global.paymentFrequency === 'Yearly'){
                    var calculatePrice = this.calculatePrice(_option?.premiumBeforeTaxes?.value > 0 ? _option.premiumBeforeTaxes.value : 0);
                    return calculatePrice;
                }else{
                    return this.getTwelveMonthsNoDepositPremium(_product, _option) / 12;
                }
            },
            getTwelveMonthsNoDepositPremium: function(_product, _option) {
                var totalPremium = 0;
                if (_option?.premiumBeforeTaxes?.value > 0) {
                    totalPremium = this.calculatePrice(
                    _option.premiumBeforeTaxes.value *
                        Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value
                    );
                }
                return totalPremium;
            },
            hitungPremi: function(hargaSekarang){
                let getSelectedPrice = 0;
                let isMinus = false;
                if(this.selectedIndex !== null){
                    getSelectedPrice = this.selectedOption.premiumBeforeTaxes?.value
                    isMinus = hargaSekarang < getSelectedPrice
                }
                let ambilHarga = isMinus ? getSelectedPrice - hargaSekarang : hargaSekarang - getSelectedPrice;
                let premi = this.selectedIndex == null ? hargaSekarang : ambilHarga
                return {isMinus: isMinus, premi: premi}
            },
            premiumShow: function(priceNext, priceCurrent, isInstalment){
                // console.log(priceNext, priceCurrent, isInstalment)
                if(priceCurrent == null){
                    priceCurrent = 0;
                }
                // priceNext = this.selectedIndex ? Global?.ciProductToPick?.optionList?.option[this.selectedIndex+1].premiumBeforeTaxes?.value : 0
                if(isInstalment){
                    priceCurrent = parseInt((priceCurrent * Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value / 12).toFixed(0));
                }
                // console.log(priceCurrent, priceNext, '===current next', priceCurrent > priceNext )
                var premiumShow = {
                    "premium": Math.abs(priceCurrent - priceNext),
                    "isMinus": Number(priceCurrent) > Number(priceNext)
                }

                return premiumShow;
            },
            scroll_left() {
                let content = document.querySelector("#custom-scroll-modal");
                let scrollAmount = 0;
                let slideTimer = setInterval(function(){
                    content.scrollLeft -= 10;
                    scrollAmount += 10;
                    if(scrollAmount >= 100){
                        window.clearInterval(slideTimer);
                    }
                }, 25);
            },
            scroll_right() {
                // console.log('masuk')
                let content = document.querySelector("#custom-scroll-modal");
                let scrollAmount = 0;
                let slideTimer = setInterval(function(){
                    content.scrollLeft += 10;
                    scrollAmount += 10;
                    if(scrollAmount >= 100){
                        window.clearInterval(slideTimer);
                    }
                }, 25);
            },
            itemScroll:function(){
                $(".item-wrapper-modal").each(function(){
                    var scrollBoxWidth = $(this).width();
                    var wrapperWidth = $(this).find(".item-modal").length * 120;
                    var scrollLeft = $(document).width() < 450 ? 116 : 116 ;

                    if( wrapperWidth > scrollBoxWidth ){
                        var activeIndex = $(this).find(".item-modal.active").index();
                        $(this).animate({scrollLeft: scrollLeft*activeIndex}, 500);
                    }
                });
            },
            getPackageLabel: function(coverName, index) {
                var vm = this;
                if("covid19".includes(coverName.toLowerCase())){
                    var label = "";
                    if (index == 0) label = vm.$t("packageLabel.saving");
                    if (index == 1) label = vm.$t("packageLabel.caring");
                    if (index == 2) label = vm.$t("packageLabel.valuable");
                    if (index == 3) label = vm.$t("packageLabel.protect");
                    return label;
                }else{
                    var label = vm.$t("packageLabel.premium");
                    if (index == 0) label = vm.$t("packageLabel.value");
                    if (index == 1) label = vm.$t("packageLabel.standard");
                    if (index == 2) label = vm.$t("packageLabel.recommended");
                    if (index == 3) label = vm.$t("packageLabel.premium");
                    return label;
                }
            },
        },
    }

</script>
<i18n>
    {   
      "en": {
        "hasbeenadded": "Benefit has been added",
        invalidInputInfo: "You haven't selected any benefits",
        "maxCover": "Room Charges max.",     
        addButton: "Add",
        cancelButton: "Cancel",
        cancerTitle : "79 Cancer Diseases",
        dengueTitle : "5 Mosquito-Borne Diseases",
        organfailureTitle : "3 Kidney Failure Conditions",
        cardiovascularTitle : "5 Cardiovascular Diseases",
        neuroTitle : "9 Neurological Conditions",
        currency: "IDR ",
        "increasePremi" : "Add",
        "decreasePremi" : "Save",
        "sumInsured" : "Sum Insured",
        "cancerDesc" : "Adding this benefit could avoid you from financial risk due to cancer diseases.",
        "neuroDesc" : "Adding this benefit could avoid you from financial risk due to neurological conditions.",
        "cardiovascularDesc" : "Adding this benefit could avoid you from financial risk due to critical cardiovascular conditions.",
        "organfailureDesc" : "Adding this benefit could avoid you from financial risk due to kidney failure conditions.",
        "dengueDesc" : "Adding this benefit could avoid you from financial risk due to mosquito-borne diseases.",
        "mio": "Mio",
        "bio": "Bio",
        "packageLabel": {
            "notcover": "Not covered",
            "valuable": "Valuable",
            "value": "Value",
            "recommended": "Recommended",
            "standard": "Standard",
            "premium": "Premium",
            "premium+": "Premium+ Plan",
            "super": "Super Plan",
            "saving": "Saving Plan",
            "caring": "Caring Plan",
            "reimbursement" : "Claim reimbursement",
            "cashless" : "Claim Cashless",
            "protect": "Protect Plan",
            "upto": "up to",
            "increasePremi" : "Add",
            "decreasePremi" : "Save",
            "sumInsured" : "Sum Insured",
            "sumInsuredDengue" : "Coverage max.",
        },
        "titleDenguePlan" : "Choose In-Patient Plan:",
        "titleChooseSumInsured" : "Choose Sum Insured",
        "peryear": "/year",
      },
      "id": {
        "hasbeenadded": "Manfaat berhasil ditambahkan",
        invalidInputInfo: "Kamu belum memilih manfaat",
        "maxCover": "Biaya Kamar maks.",
        addButton: "Tambah",
        cancelButton: "Batal",
        cancerTitle: "79 Penyakit Kanker",
        dengueTitle : "5 Penyakit Akibat Gigitan Nyamuk",
        organfailureTitle : "3 Kondisi Gagal Ginjal",
        cardiovascularTitle : "5 Penyakit Jantung​​",
        neuroTitle : "9 Penyakit Gangguan Saraf​",
        currency: "Rp",
        "increasePremi" : "Tambah Premi",
        "decreasePremi" : "Hemat Premi",
        "sumInsured" : "Pertanggungan",
        "cancerDesc" : "Menambah manfaat ini bisa mengurangi risiko finansial akibat penyakit kanker.",
        "neuroDesc" : "Menambah manfaat ini bisa mengurangi risiko finansial akibat penyakit gangguan saraf.",
        "cardiovascularDesc" : "Menambah manfaat ini bisa mengurangi risiko finansial akibat berbagai kondisi kritis jantung.",
        "organfailureDesc" : "Menambah manfaat ini bisa mengurangi risiko finansial akibat penyakit gagal ginjal.",
        "dengueDesc" : "Menambah manfaat ini bisa mengurangi risiko finansial jika terkena penyakit akibat gigitan nyamuk. ",
        "mio": "Juta",
        "bio": "Miliar",
        "packageLabel": {
            "notcover": "Tidak Ditanggung",
            "valuable": "Ekonomis",
            "value": "Ekonomis",
            "recommended": "Rekomendasi",
            "saving": "Paket Hemat",
            "standard": "Standar",
            "premium": "Premium",
            "premium+": "Paket Premium+",
            "super": "Paket Super",
            "reimbursement" : "Klaim reimbursement",
            "cashless" : "Klaim Cashless",
            "caring": "Paket Perawatan",
            "protect": "Paket Proteksi",
            "upto": "sampai dengan",
            "increasePremi" : "Tambah Premi",
            "decreasePremi" : "Hemat Premi",
            "sumInsured" : "Pertanggungan",
            "sumInsuredDengue" : "Pertanggungan maks.",
        },
        "titleDenguePlan" : "Pilih Manfaat Rawat Inap",
        "titleChooseSumInsured" : "Pilih Nominal Uang Pertanggungan",
        "peryear": "/tahun",
      },
    }
</i18n>
<style scoped>
    .plan-title{
        color: #6F6F6F;
        font-family: Public Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; 
        letter-spacing: 0.24px;
    }
    .toast{
        position: absolute; 
        right: 20px;
        background: rgba(0, 0, 0, 0.70);
        display: flex;
        width: 296px;
        padding: 8px 16px;
        align-items: center;
        border-radius: 4px;
    }
    .toast-body{
        gap: 12px;
        color: #FFF;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
    }
    .desc{
        color: #525252;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 
    }
    .addbutton{
        display: flex;
        padding: 8px 8px;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 8px;
        flex: 1 0 0;
        flex-wrap: wrap;
        color: #FFF;
        text-align: center;
        font-family: Public Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; 
        letter-spacing: 0.28px;
        border-radius: 8px;
        background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
        cursor: pointer;
        /* margin: 0 16px 16px 16px; */
    }
    .addbutton:hover{
        background: #C73E1E;
    }
    .cancelbutton{
        display: flex;
        padding: 8px 8px;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 8px;
        flex: 1 0 0;
        flex-wrap: wrap;
        color: #EF4A24;
        text-align: center;
        font-family: Public Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; 
        letter-spacing: 0.28px;
        border-radius: 8px;
        background: #FFEFEB;
        cursor: pointer;
    }
    .cancelbutton:hover{
        background: #FFE7E1;
    }
    .contentOuterBox{
        padding: 16px 0 0 0;
    }
    .contentBox{
        margin: 0 16px 16px 16px;
        display: flex;
        flex-direction: column;
    }
    .titleContentBox{
        display: flex;
        padding: 12px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background: #F7F7F7;
        color: #000;
        font-family: Public Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; 
        letter-spacing: 0.28px;
        align-items: center;
        /* height: 70px; */
        justify-content: space-between;
    }
    .titleContentBox:hover{
        background: #E8E8E8;
    }
    .listContainer{
        margin-bottom: 4px;
        max-height: 300px;
        overflow: auto;
        margin-right: 10px;
    }
    .listContainer2{
        overflow: auto;
    }
    .listContainer::-webkit-scrollbar{
        scrollbar-color: #F4866D #dddddd;
        width: 4px;
        height: 80%;
    }
    
    .listContainer::-webkit-scrollbar-track{
        background:#dddddd;
        -webkit-border-radius:10px;
        border-radius:10px;
        margin-top: 25px;
        margin-bottom: 25px;
        margin-right: 10px;
    }
    .listContainer::-webkit-scrollbar-thumb{
        border-radius:10px;
        background:#F4866D;
    }
    .listContent {
        display: flex;
        padding: 8px;
        align-items: flex-start;
        border-bottom: 1px solid #F7F7F7;
        color: #6F6F6F;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 0 5px;
        width: 300px;
    }
    .benefits{
        padding: 4px 8px;
        align-items: flex-start;
    }
    .benefits p{
        color: #6F6F6F;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0 5px;
    }
    .benefits li{
        color: #6F6F6F;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0 5px;
        margin-left: -20px;
    }
    .benefits li ol li{
        color: #6F6F6F;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0 5px;
        margin-left: -28px;
    }
    .benefits h6{
        align-items: flex-start;
        color: #6F6F6F;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        margin: 0 5px;
    }
    .modal {
        display: block;
    }
    .modal-dialog-scrollable {
      display: -ms-flexbox;
      display: flex;
      max-height: calc(100% - 1rem);
    }
    
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 1rem);
      overflow: hidden;
    }
    
    .modal-dialog-scrollable .modal-content {
      overflow-y: auto;
      /* max-height: 512px !important; */
    }
    .modal-mv {
        text-align: center;
        padding: 0!important;
        background-color: rgba(0,0,0,0.7)!important;
    }
    .modal-mv .modal-dialog {
        /* margin: 5rem auto!important; */
        /* top: 10%; */
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        max-width: 300px !important;
    }
    .modal-mv .modal-dialog.garage{
        top: 4% !important;
    }
    .modal-mv .modal-dialog.voucher{
        top: 1% !important;
    }
    .modal-mv .modal-content.voucher {
        border-radius: 8px!important;
        background-image: url("../../../../public/images/motor-vehicle/bg-voucher.svg"), linear-gradient(180deg, #00539A 0%, #003A6D 100%);
        background-position: top center;
        background-repeat: no-repeat;
        position: static;
    }
    
    .modal-mv .modal-content {
        border-radius: 8px!important;
    }
    
    .modal-mv .modal-content .modal-title{
        margin:16px 16px 0px 16px;
    }
    .modal-mv .modal-content.voucher .modal-title .title-modal{
        font-weight: 800;
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        width: 100%;
        max-width: none !important;
    }
    .modal-mv .modal-content .modal-title .title-modal{
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: #000;
        max-width: 228px;
    }
    
    .modal-mv .modal-content .modal-title .btn-close-modal{
        padding: 0px;
        width: 28px;
        height: 28px;
        background-color: #F4F9FF;
        background-image: url("../../../../public/images/motor-vehicle/btnx.svg");
        background-repeat: no-repeat, no-repeat;
        background-position: top 7px left 7px;
        border-radius: 48px;
        margin-left: auto;
        color: #A8A8A8;
    }
    
    .modal-mv .modal-content .modal-title .btn-close-modal.voucher{
        opacity: 1;
        position: absolute;
        top: -10px;
        z-index: 1760;
        right: 2px;
    }
    
    .modal-mv .modal-content .btn-close-modal:hover{
        background-image: url("../../../../public/images/motor-vehicle/btnx2.svg");
        background-repeat: no-repeat, no-repeat;
        background-position: top 7px left 7px;
        background-color: #D6E6FF;
    }  
    .slideIn {
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .slideOut {
        -webkit-animation-name: slideInDown;
        animation-name: slideInDown;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .slideRight {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .slideLeft {
        -webkit-animation-name: slideInLeft;
        animation-name: slideInLeft;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .shake {
        -webkit-animation-name: shake;
        animation-name: shake;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    
    @-webkit-keyframes slideInUp {
        0% {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
    @keyframes slideInUp {
        0% {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
    
    @-webkit-keyframes slideInDown {
        from {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            visibility: visible;
        }
        to {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }
    }
    @keyframes slideInDown {
        from {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            visibility: visible;
        }
        to {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }
    }
    
    
    @-webkit-keyframes slideInRight {
        from { 
            -webkit-transform: translateX(100%); 
            transform: translateX(100%);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    @keyframes slideInRight {
        from { 
            -webkit-transform: translateX(100%); 
            transform: translateX(100%);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    
    @-webkit-keyframes slideInLeft {
        from { 
            -webkit-transform: translateX(0); 
            transform: translateX(0);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
    }
    @keyframes slideInLeft {
        from { 
            -webkit-transform: translateX(0); 
            transform: translateX(0);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
    }
    
    @-webkit-keyframes shake {
        0%, 100% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        10%, 30%, 50%, 70%, 90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }
        20%, 40%, 60%, 80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    }
    @keyframes shake {
        0%, 100% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        10%, 30%, 50%, 70%, 90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }
        20%, 40%, 60%, 80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    } 
    
    @media screen and (max-width: 480px) {
        .modal-dialog {
            max-width: 93%;
        }
    }

    .illLogo{
        width: 24px;
        height: 24px;
    }
    .center-modal{
        /* display: flex; 
        justify-content: center; 
        align-items: center; */
    }
    .button-center{
        display: flex; 
        justify-content: center; 
        align-items: center;
        padding: 16px;
    }
    .button-center span{
        width: 8px;
    }

    .item-wrapper-modal {
        display: flex;
        flex-direction: row;
        gap: 8px;
        width: 100%;
        scrollbar-gutter: stable both-edges;
        overflow-x: auto;
        padding: 0 0 25px 0;
    }
    .item-wrapper-modal::-webkit-scrollbar{
        width: 0px;
        height: 4px;
    }
    .item-wrapper-modal::-webkit-scrollbar-track{
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #dddddd;
        margin-right: 100px;
    }
    .item-wrapper-modal::-webkit-scrollbar-thumb{
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #F4866D;
    }
    .item-wrapper-modal::-webkit-scrollbar-thumb:hover{
        background:#F26848;
    }
    .item-wrapper-modal .item-modal {
        display: flex;
        height: 124px; 
        width: 112px !important; 
        padding: 16px 4px;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        border-radius: 8px;
        border: 1px solid #CCC;
        cursor: pointer;
    }
    .item-wrapper-modal .item-modal.dengue{
        padding: 0 4px 16px 4px;
        height: auto;
    }
    .item-wrapper-modal .item-modal.dengue.active{
        display: flex; 
        flex-direction: column; 
        justify-content: space-between; 
        align-items: center; 
        padding: 0 4px 16px 4px;
        border-radius: 8px;
        border: 4px solid #1584CF;
        background: #00539A;
    }
    .item-wrapper-modal .item-modal.dengue.active .black500{
        color: #fff;
    }
    .item-wrapper-modal .item-modal.dengue.active .packageLabel{
        color: #003A6E;
        position: relative;
        top: -4px;
    }
    .item-wrapper-modal .item-modal:hover{
        border: 1px solid #8d8d8d;
    }
    .item-wrapper-modal .item-modal.active {
        display: flex; 
        flex-direction: column; 
        justify-content: space-between; 
        align-items: center; 
        padding: 16px 8px;
        border-radius: 8px;
        border: 4px solid #1584CF;
        background: #00539A;
        
    }
    .item-wrapper-modal .item-modal.active div{
        color: #fff;
        
    }
    .black500 {
        color: #000;
        text-align: center;
        font-family: Public Sans;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; 
        letter-spacing: 0.2px;
    }
    .black700 {
        color: #000;
        text-align: center;
        font-family: Public Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; 
        letter-spacing: 0.28px;
        width: 100px;
    }
    .boxGrey400 {
        display: flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #F7F7F7;
        color: #A8A8A8;
        font-family: Public Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; 
        letter-spacing: 0.24px;
    }
    .circleContainer {
        height: 45px;
    }
    .circle-check1 {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        margin-top: 4px;
        padding: 2px;
        border-radius: 50%;
        background-color: #EF4A24;
        width: 24px;
        height: 24px;
        display: flex;
    }
    @-webkit-keyframes fadeInUp {
        0% {
            opacity: 1;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }
    @keyframes fadeInUp {
        0% {
            opacity: 1;
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
        }
        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }
    @media (prefers-reduced-motion: no-preference) {
    .fade-in{
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    }
    @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
    }
    .btn-left-modal{
        z-index: 5;
        position:absolute;
        transform: translateY(-60%);
        right: 65px;
        bottom: 43px;
        border-radius: 50%;
        background-color: #FFF;
        border: 1px solid #f5f5f5;
        box-shadow: #e3e3e3 0px 1px 5px 0px;
        -webkit-box-shadow: #e3e3e3 0px 1px 1px 0px  !important;
    }
    .btn-right-modal{
        z-index: 5;
        position:absolute;
        transform: translateY(-60%);
        right: 16px;
        bottom: 43px;
        border-radius: 50%;
        background-color: #FFF;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 2px 6px 0px rgba(0, 58, 109, 0.10);
        -webkit-box-shadow: #e3e3e3 0px 1px 1px 0px  !important;
    }
    .btn-right-modal:hover{
        background-color: #EF4A24;
        border: 1px solid #EF4A24;
        box-shadow: 0px 2px 16px 16px rgba(0, 58, 109, 0.16);
        -webkit-box-shadow: #e3e3e3 0px 3px 3px 0px  !important;
    }
    .btn-right-modal:hover svg{
        fill: #fff;
    }
    .btn-left-modal:hover{
        background-color: #EF4A24;
        border: 1px solid #EF4A24;
        box-shadow: 0px 2px 16px 16px rgba(0, 58, 109, 0.16);
        -webkit-box-shadow: #e3e3e3 0px 3px 3px 0px  !important;
    }
    .btn-left-modal:hover svg{
        fill: #fff;
    }
    .invalidInput {
        color: var(--red-60, #DA1E28);
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; 
        letter-spacing: 0.2px;
        margin: 8px 16px 0 16px;
        display: flex;
        padding: 8px 16px;
        /* justify-content: center;
        align-items: center; */
        gap: 12px;
        border-radius: 4px;
        border-top: 0.5px solid #FF8389;
        border-right: 0.5px solid #FF8389;
        border-bottom: 2px solid #FF8389;
        border-left: 0.5px solid #FF8389;
        background: #FFF1F1;
    }
    .packageLabel{
        display: flex;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 0px 0px 8px 8px;
        background: #F0FAFF;
        color: #003A6E;
        font-family: Public Sans;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.2px;
    }
    </style>