<template>
    <div class="modal modal-mv" v-if="show">
      <div class="modal-dialog modal-dialog-scrollable" :style="'width:' + width +';'" :class="classModal">
        <div class="modal-content" :class="{notitle: title == ''}">
            <div class="modal-title" v-if="title !== ''">
                <div class="d-flex">
                    <h1 class="title-modal">{{title}}</h1>
                    <button class="btn btn-close-modal" :class="{voucher: voucher}" @click="close()" v-if="this.title != '' && !voucherClose"></button>
                </div>
            </div> 
          <slot />
        </div>
      </div>
    </div>
    </template>
    
    <script>
    
    export default {
        name: "Modal",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            voucher: {
                type: Boolean,
                default: false
            },
            voucherClose: {
                type: Boolean,
                default: false
            },
            width: {
                type: String,
                default: "500px"
            },
            title: {
                type: String,
                default: ""
            },
            classModal: {
                type: String,
                default: ""
            }
        },
        methods: {
            close(){
                this.$emit("close");
            }
        }
    }
    </script>
    <style scoped>
    .notitle {
        padding-top: 16px;
    }
    .modal {
        display: block;
    }
    .modal-dialog-scrollable {
      display: -ms-flexbox;
      display: flex;
      max-height: calc(100% - 1rem);
    }
    
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 1rem);
      overflow: hidden;
    }
    
    .modal-dialog-scrollable .modal-content {
      overflow-y: auto;
    }
    .modal-mv {
        text-align: center;
        padding: 0!important;
        background-color: rgba(0,0,0,0.7)!important;
    }
    .modal-mv .modal-dialog {
        /* margin: 5rem auto!important; */
        top: 20%;
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        max-width: 300px !important;
    }
    .modal-mv .modal-dialog.garage{
        top: 4% !important;
    }
    .modal-mv .modal-dialog.voucher{
        top: 1% !important;
    }
    .modal-mv .modal-content.voucher {
        border-radius: 8px!important;
        background-image: url("../../../../public/images/motor-vehicle/bg-voucher.svg"), linear-gradient(180deg, #00539A 0%, #003A6D 100%);
        background-position: top center;
        background-repeat: no-repeat;
        position: static;
    }
    
    .modal-mv .modal-content {
        border-radius: 8px!important;
    }
    
    .modal-mv .modal-content .modal-title{
        margin:16px 16px 0px 16px;
    }
    .modal-mv .modal-content .modal-body{
        padding:0;
    }
    .modal-mv .modal-content.voucher .modal-title .title-modal{
        font-weight: 800;
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        width: 100%;
        max-width: none !important;
    }
    .modal-mv .modal-content .modal-title .title-modal{
        color: #161616;
        font-family: Public Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 26px;
        letter-spacing: 0.4px;
        flex: 1;
    }
    
    .modal-mv .modal-content .modal-title .btn-close-modal{
        padding: 0px;
        width: 28px;
        height: 28px;
        background-color: #F4F9FF;
        background-image: url("../../../../public/images/motor-vehicle/btnx.svg");
        background-repeat: no-repeat, no-repeat;
        background-position: center center;
        border-radius: 48px;
        margin-left: auto;
        color: #A8A8A8;
    }
    
    .modal-mv .modal-content .modal-title .btn-close-modal.voucher{
        opacity: 1;
        position: absolute;
        top: -10px;
        z-index: 1760;
        right: 2px;
    }
    
    .modal-mv .modal-content .btn-close-modal:hover{
        background-image: url("../../../../public/images/motor-vehicle/btnx2.svg");
        background-repeat: no-repeat, no-repeat;
        background-position: top 7px left 7px;
        background-color: #D6E6FF;
    }  
    .slideIn {
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .slideOut {
        -webkit-animation-name: slideInDown;
        animation-name: slideInDown;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .slideRight {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .slideLeft {
        -webkit-animation-name: slideInLeft;
        animation-name: slideInLeft;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .shake {
        -webkit-animation-name: shake;
        animation-name: shake;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    
    @-webkit-keyframes slideInUp {
        0% {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
    @keyframes slideInUp {
        0% {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
    
    @-webkit-keyframes slideInDown {
        from {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            visibility: visible;
        }
        to {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }
    }
    @keyframes slideInDown {
        from {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            visibility: visible;
        }
        to {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }
    }
    
    
    @-webkit-keyframes slideInRight {
        from { 
            -webkit-transform: translateX(100%); 
            transform: translateX(100%);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    @keyframes slideInRight {
        from { 
            -webkit-transform: translateX(100%); 
            transform: translateX(100%);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    
    @-webkit-keyframes slideInLeft {
        from { 
            -webkit-transform: translateX(0); 
            transform: translateX(0);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
    }
    @keyframes slideInLeft {
        from { 
            -webkit-transform: translateX(0); 
            transform: translateX(0);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
    }
    
    @-webkit-keyframes shake {
        0%, 100% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        10%, 30%, 50%, 70%, 90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }
        20%, 40%, 60%, 80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    }
    @keyframes shake {
        0%, 100% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        10%, 30%, 50%, 70%, 90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }
        20%, 40%, 60%, 80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    } 
    
    @media screen and (max-width: 480px) {
        .modal-dialog {
            max-width: 93%;
        }
    }
    </style>