<template>
    <div class="modal modal-mv d-flex align-items-center justify-content-center" v-if="show">
      <div class="modal-dialog modal-dialog-scrollable" :style="'width:' + width +';'" :class="classModal">
        <div class="modal-content" :class="{voucher: voucher}">
            <div class="modal-title">
                <div class="d-flex">
                    <h1 class="title-modal">{{ $t('modalTitle') }}</h1>
                    <button class="btn btn-close-modal" :class="{voucher: voucher}" @click="close()"></button>
                </div>
            </div> 
            <div class="contentOuterBox">
                <div class="contentBox">
                    <div class="titleContentBox" @click="showContent = 1">
                        <div style="margin-right: 8px;">
                            <div>
                                <img class="illLogo" :src="'/images/' + logoSelector[selector]" height="24px" width="24px"/>
                            </div>
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                                <path d="M5.03922 14.0068L9.07059 18.0344L19.149 7.96533" stroke="#525252" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> -->
                        </div>
                        <div class="d-flex flex-grow-1">
                        {{ selector == 'cancer' ? $t('cancerTitle') : selector == 'dengue' ? $t('dengueTitle') :  selector == 'organfailure' ? $t('organfailureTitle') :  selector == 'cardiovascular' ? $t('cardiovascularTitle') : selector == 'neuro' ? $t('neuroTitle') : '' }}
                        </div>
                        <div style="margin-left: 8px;">
                            <svg v-if="showContent === 1" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7071 12.7901C14.3166 13.1806 13.6834 13.1806 13.2929 12.7901L10 9.49722L6.70711 12.7901C6.31658 13.1806 5.68342 13.1806 5.29289 12.7901C4.90237 12.3996 4.90237 11.7664 5.29289 11.3759L9.29289 7.3759C9.68342 6.98538 10.3166 6.98538 10.7071 7.3759L14.7071 11.3759C15.0976 11.7664 15.0976 12.3996 14.7071 12.7901Z" fill="#CCCCCC"/>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.70711 7.29289L10 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z" fill="#111827"/>
                            </svg>
                        </div>
                    </div>
                    <div div class="d-flex mt-1" v-if="showContent === 1" >
                        <div class="listContainer" id="listContainer1">
                            <div class="listContent" v-for="illness in coverageIllness.illness" >
                                {{ illness }}
                            </div>
                        </div>
                        <div v-if="coverageIllness.illness?.length > 7" class="d-flex flex-column" style="height: 300px; position: absolute; right: 17px; align-items: center; justify-content: space-between;">
                            <svg @click="scrollUp(1)" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M20 18.1658C20 19.2703 19.1046 20.1658 18 20.1658L10 20.1658C6.68629 20.1658 4 17.4795 4 14.1658L4 6.16577C4 5.0612 4.89543 4.16577 6 4.16577L14 4.16577C17.3137 4.16577 20 6.85206 20 10.1658L20 18.1658Z" fill="#F7F7F7"/>
                                <path d="M12.6997 9.86577L15.2997 12.4658C15.6164 12.7824 15.6871 13.1448 15.5117 13.5528C15.3364 13.9608 15.0241 14.1651 14.5747 14.1658L9.42474 14.1658C8.97474 14.1658 8.66208 13.9614 8.48674 13.5528C8.31141 13.1441 8.38241 12.7818 8.69974 12.4658L11.2997 9.86577C11.3997 9.76577 11.5081 9.69077 11.6247 9.64077C11.7414 9.59077 11.8664 9.56577 11.9997 9.56577C12.1331 9.56577 12.2581 9.59077 12.3747 9.64077C12.4914 9.69077 12.5997 9.76577 12.6997 9.86577Z" fill="#F26848"/>
                            </svg>
                            <svg @click="scrollDown(1)" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M4 6.16577C4 5.0612 4.89543 4.16577 6 4.16577L14 4.16577C17.3137 4.16577 20 6.85206 20 10.1658V18.1658C20 19.2703 19.1046 20.1658 18 20.1658L10 20.1658C6.68629 20.1658 4 17.4795 4 14.1658L4 6.16577Z" fill="#F7F7F7"/>
                                <path d="M11.3003 14.4658L8.70026 11.8658C8.38359 11.5491 8.31292 11.1868 8.48826 10.7788C8.66359 10.3708 8.97592 10.1664 9.42526 10.1658L14.5753 10.1658C15.0253 10.1658 15.3379 10.3701 15.5133 10.7788C15.6886 11.1874 15.6176 11.5498 15.3003 11.8658L12.7003 14.4658C12.6003 14.5658 12.4919 14.6408 12.3753 14.6908C12.2586 14.7408 12.1336 14.7658 12.0003 14.7658C11.8669 14.7658 11.7419 14.7408 11.6253 14.6908C11.5086 14.6408 11.4003 14.5658 11.3003 14.4658Z" fill="#F26848"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="contentBox">
                    <div class="titleContentBox" @click="showContent = 2">
                        <div style="margin-right: 8px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                <path d="M9.07059 5.03457H7.0549C5.94167 5.03457 5.03922 5.93619 5.03922 7.04839V19.1313C5.03922 20.2435 5.94167 21.1451 7.0549 21.1451H17.1333C18.2466 21.1451 19.149 20.2435 19.149 19.1313V7.04839C19.149 5.93619 18.2466 5.03457 17.1333 5.03457H15.1176M9.07059 5.03457C9.07059 6.14677 9.97304 7.04839 11.0863 7.04839H13.102C14.2152 7.04839 15.1176 6.14677 15.1176 5.03457M9.07059 5.03457C9.07059 3.92237 9.97304 3.02075 11.0863 3.02075H13.102C14.2152 3.02075 15.1176 3.92237 15.1176 5.03457M12.0941 12.0829H15.1176M12.0941 16.1106H15.1176M9.07059 12.0829H9.08067M9.07059 16.1106H9.08067" stroke="black" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div class="d-flex flex-grow-1">
                            {{$t('benefitLabel')}}
                        </div>
                        <div style="margin-left: 8px;">
                            <svg v-if="showContent === 2" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7071 12.7901C14.3166 13.1806 13.6834 13.1806 13.2929 12.7901L10 9.49722L6.70711 12.7901C6.31658 13.1806 5.68342 13.1806 5.29289 12.7901C4.90237 12.3996 4.90237 11.7664 5.29289 11.3759L9.29289 7.3759C9.68342 6.98538 10.3166 6.98538 10.7071 7.3759L14.7071 11.3759C15.0976 11.7664 15.0976 12.3996 14.7071 12.7901Z" fill="#CCCCCC"/>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.70711 7.29289L10 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z" fill="#111827"/>
                            </svg>
                        </div>
                    </div>
                    <div class="d-flex mt-1" v-if="showContent === 2">
                        <div class="listContainer listContainer2" id="listContainer2">
                            <div class="benefits" v-for="benefit in coverageIllness.benefits" :key="benefit.title">
                                <h6>{{ benefit.title }}</h6>
                                <p>{{ benefit.desc }}</p>
                                <p v-if="benefit.child" v-for="child in benefit.child" style="margin-top: 10px;" :key="child">{{ child }}</p>
                                <ul v-if="benefit.childUL" v-for="child in benefit.childUL" style="margin-top: 10px;" :key="child.content">
                                    <li>{{child.content}}
                                        <ol start="1" v-if="child.childOL" style="margin-top: 10px;">
                                            <li v-for="child0 in child.childOL"  :key="child0" style="margin-top: 5px;">{{ child0 }}</li>
                                        </ol>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="showScrollDesc" class="d-flex flex-column" style="height: 300px; position: absolute; right: 17px; align-items: center; justify-content: space-between;">
                            <svg @click="scrollUp(2)"  style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M20 18.1658C20 19.2703 19.1046 20.1658 18 20.1658L10 20.1658C6.68629 20.1658 4 17.4795 4 14.1658L4 6.16577C4 5.0612 4.89543 4.16577 6 4.16577L14 4.16577C17.3137 4.16577 20 6.85206 20 10.1658L20 18.1658Z" fill="#F7F7F7"/>
                                <path d="M12.6997 9.86577L15.2997 12.4658C15.6164 12.7824 15.6871 13.1448 15.5117 13.5528C15.3364 13.9608 15.0241 14.1651 14.5747 14.1658L9.42474 14.1658C8.97474 14.1658 8.66208 13.9614 8.48674 13.5528C8.31141 13.1441 8.38241 12.7818 8.69974 12.4658L11.2997 9.86577C11.3997 9.76577 11.5081 9.69077 11.6247 9.64077C11.7414 9.59077 11.8664 9.56577 11.9997 9.56577C12.1331 9.56577 12.2581 9.59077 12.3747 9.64077C12.4914 9.69077 12.5997 9.76577 12.6997 9.86577Z" fill="#F26848"/>
                            </svg>
                            <svg @click="scrollDown(2)" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M4 6.16577C4 5.0612 4.89543 4.16577 6 4.16577L14 4.16577C17.3137 4.16577 20 6.85206 20 10.1658V18.1658C20 19.2703 19.1046 20.1658 18 20.1658L10 20.1658C6.68629 20.1658 4 17.4795 4 14.1658L4 6.16577Z" fill="#F7F7F7"/>
                                <path d="M11.3003 14.4658L8.70026 11.8658C8.38359 11.5491 8.31292 11.1868 8.48826 10.7788C8.66359 10.3708 8.97592 10.1664 9.42526 10.1658L14.5753 10.1658C15.0253 10.1658 15.3379 10.3701 15.5133 10.7788C15.6886 11.1874 15.6176 11.5498 15.3003 11.8658L12.7003 14.4658C12.6003 14.5658 12.4919 14.6408 12.3753 14.6908C12.2586 14.7408 12.1336 14.7658 12.0003 14.7658C11.8669 14.7658 11.7419 14.7408 11.6253 14.6908C11.5086 14.6408 11.4003 14.5658 11.3003 14.4658Z" fill="#F26848"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="closeButton"  @click="close()">{{ $t('closeButton') }}</div>
        </div>
      </div>
    </div>
</template>
    
<script>
import Global from "@/store/global.js";
    export default {
        name: "Modal",
        data() {
            return {
                Global,
                showContent: 1,
                logoSelector:{
                    cancer: "Cancer.png",
                    neuro: "Neuro.png",
                    cardiovascular: "Cardiovascular.png",
                    organfailure: "OrganFailure.png",
                    dengue: "Dengue.png",
                }
            }
        },
        computed:{
            coverageIllness(){
                let idIllness = []
                let idBenefits = []
                let enIllness = []
                let enBenefits = []
                
                if(this.selector == 'cancer'){
                    idIllness = ["Bibir", "Lidah", "Bagian lidah lainnya", "Gusi", "Dasar Mulut", "Langit-Langit Mulut", "Bagian mulut lainnya", "Kelenjar Parotis", "Bagian kelenjar ludah utama lainnya", "Amandel", "Orofaring", "Nasofaring", "Sinus Piriformis", "Hipofaring", "Bagian lain pada bibir dan faring", "Esofagus", "Lambung", "Usus Halus", "Usus Besar", "Persimpangan Rektosigmoid", "Rektum", "Anus dan salurannya", "Hati dan Saluran Empedu Intrahepatik", "Kantung Empedu", "Bagian lain dari saluran empedu", "Pankreas", "Organ pencernaan lainnya", "Rongga Hidung & Telinga Tengah", "Sinus Paranasal", "Laring", "Trakea", "Bronkus dan Paru-Paru", "Kelenjar Timus", "Jantung, Mediastinum dan Pleura", "Bagian sistem pernapasan lainnya", "Tulang dan Tulang Rawan pada Tungkai", "Bagian lain dari tulang dan tulang rawan", "Kulit", "Neoplasma ganas kulit lainnya", "Mesotelioma", "Sarkoma Kaposi", "Saraf Tepi", "Retroperitoneum dan Peritoneum", "Jaringan ikat dan jaringan lunak lainnya", "Payudara", "Vulva", "Vagina", "Leher Rahim", "Rahim", "Bagian rahim lainnya", "Ovarium/Indung Telur", "Bagian lain dari organ kelamin wanita ", "Plasenta", "Penis", "Testis", "Bagian lain dari organ kelamin pria", "Ginjal termasuk pelvis ginjal", "Ureter", "Kandung Kemih", "Organ kemih lainnya", "Mata dan jaringan pelengkap mata", "Selaput Otak", "Otak", "Sumsum Tulang Belakang/Sistem Saraf Pusat", "Kelenjar Tiroid", "Kelenjar Adrenal", "Kelenjar endokrin lainnya", "Penyakit Hodgkin", "Limfoma Non-Hodgkin Folikular", "Limfoma Non-Hodgkin Difusa", "Limfoma sel T Perifer dan Kulit ", "Limfoma Non-Hodgkin lainnya", "Penyakit Imunoproliferatif", "Neoplasma Sel Plasma Ganas", "Leukemia Limfositik", "Leukemia Myeloid", "Leukemia Monositik", "Jenis Leukemia lainnya", "Limfoid dan hematopoietik lainnya"]
                    enIllness = ["Lip", "Tongue", "Other parts of tongue", "Gum", "Floor of Mouth", "Palate", "Other parts of mouth", "Parotid Gland", "Other major salivary glands", "Tonsil", "Oropharynx", "Nasopharynx", "Pyriform Sinus", "Hypopharynx", "Other sites in the lip and pharynx", "Esophagus", "Stomach", "Small Intestine", "Colon", "Rectosigmoid Junction", "Rectum", "Anus and Anal Canal", "Liver and Intrahepatic Bile Ducts", "Gallbladder", "Other parts of biliary tract", "Pancreas", "Other digestive organs", "Nasal Cavity and Middle Ear", "Accessory Sinuses", "Larynx", "Trachea", "Bronchus and Lung", "Thymus", "Heart, Mediastinum and Pleura", "Other sites in respiratory system", "Bone and Articular Cartilage of Limbs", "Other bone and articular cartilage", "Skin", "Other skin malignant neoplasms", "Mesothelioma", "Kaposi's Sarcoma", "Peripheral Nerves", "Retroperitoneum and Peritoneum", "Other connective and soft tissue", "Breast", "Vulva", "Vagina", "Cervix Uteri", "Corpus Uteri", "Uterus part unspecified", "Ovary", "Other female genital organs", "Placenta", "Penis", "Testis", "Other male genital organs", "Kidney include renal pelvis", "Ureter", "Bladder", "Other urinary organs", "Eye and Adnexa", "Meninges", "Brain", "Spinal Cord/Central Nervous System", "Thyroid Gland", "Adrenal Gland", "Other endocrine glands", "Hodgkin's Disease", "Follicular Non-Hodgkin's Lymphoma", "Diffuse Non-Hodgkin's Lymphoma", "Peripheral and Cutaneous Tcell Lymphoma", "Other Non-Hodgkin's Lymphoma", "Immunoproliferative Diseases", "Malignant Plasma Cell Neoplasms", "Lymphoid Leukemia", "Myeloid Leukemia", "Monocytic Leukemia", "Other leukemias", "Other lymphoid and hematopoietic"]
                    enBenefits = [
                        {
                            title: "Comprehensive coverage", 
                            desc: "Roojai covers all 5 types of invasive cancer (cancer developed in adjacent tissue): carcinoma, sarcoma, leukemia, lymphoma, brain, and spinal."
                        },
                        {
                            title: "Roojai Uniqueness", 
                            desc: "We include more conditions compared to traditional insurance. Our list of exclusions is limited to “potential” cancer (ie. borderline or pre-malignant tumor, and non-invasive cancer), and cancer from HIV."
                        },
                        {
                            title: "Early-Stage Coverage", 
                            desc: "If your cancer is detected at an early stage (T1 & T2), we will pay 25% of the sum insured. If after that your cancer develops to late stage (T3 & T4) we will pay the rest (75%)."
                        },
                        {
                            title: "Full Coverage for Late Stage", 
                            desc: "If the cancer is already at late stage (T3 and above) at the time of diagnosis, we will pay directly 100% of the sum insured."
                        },
                        {
                            title: "Terms", 
                            desc: "The coverage of cancer disease will end once the sum insured is fully paid 100%."
                        },
                        {
                            title: "Disclaimer", 
                            desc: "For complete terms and conditions, please refer to the insurance policy."
                        },
                    ]
                    idBenefits = [
                        {
                            title: "Pertanggungan Lengkap", 
                            desc: "Roojai menanggung 5 jenis kanker invasif (kanker yang berkembang di jaringan organ sekitarnya): karsinoma, sarkoma, leukemia, limfoma, otak, dan tulang belakang."
                        },
                        {
                            title: "Keunikan Roojai", 
                            desc: "Kami menjamin lebih banyak kondisi kanker dibandingkan asuransi tradisional. Daftar pengecualian kami hanya pada kanker “potensial” (seperti: tumor borderline atau pra-ganas, dan kanker non-invasif), dan kanker dari HIV."
                        },
                        {
                            title: "Pertanggungan Stadium Awal", 
                            desc: "Jika kamu didiagnosis kanker stadium awal (T1 & T2), maka kamu akan menerima pembayaran 25% dari uang pertanggungan. Jika kemudian kanker tersebut berkembang menjadi stadium akhir (T3 & T4), maka kamu akan menerima sisa uang pertanggungan (75%)."
                        },
                        {
                            title: "Pertanggungan Stadium Akhir", 
                            desc: "Jika kamu sudah menderita kanker stadium akhir (T3 dan di atasnya) pada saat diagnosis, kami akan langsung membayar uang pertanggungan 100%."
                        },
                        {
                            title: "Ketentuan", 
                            desc: "Pertanggungan terhadap penyakit kanker akan berakhir setelah uang pertanggungan dibayarkan 100%."
                        },
                        {
                            title: "Disclaimer", 
                            desc: "Untuk syarat dan ketentuan selengkapnya, mohon mengacu pada polis asuransi."
                        },
                    ]
                }

                if(this.selector == 'neuro'){
                    idIllness = ["Koma akibat penyakit", "Koma akibat kecelakaan", "Penyakit Parkinson", "Penyakit Alzheimer", "Kelumpuhan akibat penyakit Alzheimer", "Kelumpuhan akibat penyakit Parkinson", "Koma Vegetatif (Sindrom Apalik)", "Bedah Mikro Klipping Aneurisma", "Kraniotomi dengan kondisi tertentu"]
                    enIllness = ["Coma from illness", "Coma from accident", "Parkinson's Disesase", "Alzheimer's Disease", "Paralysis arising from Alzheimer's disease", "Paralysis arising from Parkinson's disease", "Vegetative Coma (Apallic Syndrome)", "Aneurysm Microsurgical Clipping", "Craniotomy with specific conditions"]
                    enBenefits = [
                        {
                            title: "9 Conditions Covered ", 
                            desc: "Coma from both illness and accident, Parkinson’s disease, Alzheimer’s disease, paralysis arising from Parkinson’s disease or Alzheimer’s disease, vegetative coma (Apallic Syndrome), aneurysm, microsurgical clipping, craniotomy with specific conditions."
                        },
                        {
                            title: "Roojai Uniqueness", 
                            desc: "We have fewer exclusions compared to traditional insurance: our key criteria for coverage are activity in daily living (ADL), medical assessment, and claim analysis."
                        },
                        {
                            title: "Full payment upon diagnosis", 
                            desc: "You will receive the full sum insured if one of these conditions is diagnosed by a neurologist. "
                        },
                        {
                            title: "Claims Requirement", 
                            desc: "Besides diagnosis by a neurologist, the following conditions apply:",
                            child: [
                                'Coma: no external reaction to stimuli, requires life support for minimum 96 hours, confirmed neurological persisting deficit. (No coverage if coma is medically related with drug or alcohol abuse).',
                                'Parkinson: along with assessment on inability for the patient to perform daily routine tasks on his/her own (ADL).',
                                'Alzheimer: along with assessment on inability for the patient to perform daily routine tasks on his/her own (ADL). ',
                                'Aneurysm: confirmed by angiogram requiring neurosurgeon to perform surgery.',
                            ]
                        },
                        {
                            title: "Disclaimer", 
                            desc: "For complete terms and conditions, please refer to the insurance policy."
                        },
                    ]
                    idBenefits = [
                        {
                            title: "9 Kondisi yang Ditanggung", 
                            desc: "Koma karena sakit dan kecelakaan, penyakit Parkinson, penyakit Alzheimer, kelumpuhan akibat penyakit Parkinson atau penyakit Alzheimer, koma vegetatif (Sindrom Apallic), aneurisma, microsurgical clipping/ kraniotomi dengan kondisi tertentu."
                        },
                        {
                            title: "Keunikan Roojai", 
                            desc: "Kami memiliki lebih sedikit pengecualian dibandingkan asuransi tradisional. Pertanggungan akan diberikan jika memenuhi syarat utama kami: pemenuhan aktivitas kehidupan sehari-hari, penilaian medis, dan analisa klaim."
                        },
                        {
                            title: "Pembayaran penuh setelah diagnosis", 
                            desc: "Kamu akan menerima uang pertanggungan secara penuh jika terdiagnosis salah satu dari 9 kondisi oleh ahli saraf."
                        },
                        {
                            title: "Persyaratan Klaim", 
                            desc: "Selain diagnosis oleh ahli saraf, berlaku juga ketentuan berikut:",
                            child: [
                                'Koma: tidak ada reaksi eksternal terhadap rangsangan, membutuhkan alat bantu hidup minimal 96 jam, defisit neurologis yang menetap. (Pertanggungan tidak berlaku jika koma diakibatkan oleh penyalahgunaan obat atau alkohol).',
                                'Parkinson: disertai dengan penilaian ketidakmampuan pasien untuk melakukan aktivitas kehidupan sehari-hari sendiri.',
                                'Alzheimer:  disertai dengan penilaian ketidakmampuan pasien untuk melakukan aktivitas kehidupan sehari-hari sendiri.',
                                'Aneurisma: ditegakkan dengan pemeriksaan angiogram yang mengharuskan ahli bedah saraf untuk melakukan operasi.',
                            ]
                        },
                        {
                            title: "Disclaimer", 
                            desc: "Untuk syarat dan ketentuan selengkapnya, mohon mengacu pada polis asuransi."
                        },

                    ]
                }

                if(this.selector == 'cardiovascular'){
                    idIllness = ["Operasi Bypass Jantung", "Serangan Jantung", "Stroke Iskemik", "Stroke Hemoragik", "Penyakit Jantung Koroner"]
                    enIllness = ["Coronary Artery Bypass Grafting (CABG)", "Heart Attack", "Ischemic Stroke", "Haemorrhagic Stroke", "Coronary Artery Disease"]
                    enBenefits = [
                        {
                            title: "Covers a wide range of cases", 
                            desc: "Coronary artery bypass grafting (CABG), heart attack, ischemic and haemorrhagic stroke, and coronary artery disease."
                        },
                        {
                            title: "Roojai Uniqueness", 
                            desc: "We have fewer exclusions compared to traditional insurance."
                        },
                        {
                            title: "Full payment upon diagnosis", 
                            desc: "You will receive the full sum insured if one of these conditions is met:",
                            childUL: [
                                {
                                    content: 'Ischemic or Haemorrhagic Stroke means sudden neurological deficit caused by clot (“blood stone”) or haemorrhage (“blood leak”) in brain. Neurological deficit should be confirmed by CT scan or MRI and physician specialist should confirm disability for at least 60 days from day of diagnosis.',
                                    childOL: null
                                },
                                {
                                    content: 'Acute heart attack means patient suffered a heart attack with 3 conditions met:',
                                    childOL: [
                                        'Patient has a record of angina pain (chest pain),',
                                        'Blood test confirms Troponin is X3 standard level or CKMB is X2 standard level, and',
                                        'Electrocardiogram shows clear signs of heart attack. ',
                                    ]
                                },
                                {
                                    content: 'Coronary artery disease confirmed by a cardiologist requiring an open chest surgery or coronary artery by-pass to treat.',
                                    childOL: null
                                },
                            ]
                        },
                        {
                            title: "Disclaimer", 
                            desc: "For complete terms and conditions, please refer to the insurance policy."
                        },
                    ]
                    idBenefits = [
                        {
                            title: "Menjamin berbagai kasus", 
                            desc: "Operasi bypass jantung koroner, serangan jantung, stroke iskemik dan hemoragik, dan penyakit jantung koroner."
                        },
                        {
                            title: "Keunikan Roojai", 
                            desc: "Memiliki lebih sedikit pengecualian dibandingkan asuransi tradisional."
                        },
                        {
                            title: "Pembayaran penuh setelah diagnosis", 
                            desc: "Kamu akan menerima uang pertanggungan secara penuh jika salah satu dari kondisi-kondisi berikut terjadi:",
                            childUL: [
                                {
                                    content: 'Stroke iskemik atau hemoragik berarti defisit neurologis mendadak yang disebabkan oleh pembekuan darah atau perdarahan di otak. Defisit neurologis harus dikonfirmasi melalui CT scan atau MRI, dan dokter spesialis harus mengonfirmasi bahwa cacat berlangsung setidaknya selama 60 hari sejak hari diagnosis.',
                                    childOL: null
                                },
                                {
                                    content: 'Serangan jantung akut berarti pasien mengalami serangan jantung dengan 3 kondisi berikut:',
                                    childOL: [
                                        'Pasien memiliki catatan nyeri angina (nyeri dada),',
                                        'Tes darah mengonfirmasi bahwa Troponin mencapai 3 kali level standar atau CKMB mencapai 2 kali level standar, dan',
                                        'Elektrokardiogram menunjukkan tanda-tanda jelas serangan jantung.',
                                    ]
                                },
                                {
                                    content: 'Penyakit jantung koroner yang dikonfirmasi oleh seorang kardiolog dan memerlukan operasi dada terbuka atau operasi bypass jantung koroner untuk pengobatan.',
                                    childOL: null
                                },
                            ]
                        },
                        {
                            title: "Disclaimer", 
                            desc: "Untuk syarat dan ketentuan selengkapnya, mohon mengacu pada polis asuransi."
                        },
                    ]
                }
                if(this.selector == 'organfailure'){
                    idIllness = ["Gagal Ginjal Stadium Akhir", "Transplantasi Ginjal", "Dialisis Ginjal/Cuci Darah"]
                    enIllness = ["End Stage Renal Failure", "Kidney Transplant", "Kidney Dialysis"]
                    enBenefits = [
                        {
                            title: "3 Conditions Covered", 
                            desc: "End stage renal failure, kidney transplant, and regular kidney dialysis."
                        },
                        {
                            title: "Roojai Uniqueness", 
                            desc: "We have fewer exclusions compared to traditional insurance."
                        },
                        {
                            title: "Full payment upon diagnosis", 
                            desc: "You will receive the full sum insured if one of these conditions is met: confirmation of end stage renal failure of both kidneys by a specialist which requires continuous dialysis, or a kidney transplant."
                        },
                        {
                            title: "Disclaimer", 
                            desc: "For complete terms and conditions, please refer to the insurance policy."
                        },
                    ]
                    idBenefits = [
                        {
                            title: "Menanggung 3 Kondisi", 
                            desc: "Gagal ginjal stadium akhir, transplantasi ginjal, dan dialisis ginjal secara berkala."
                        },
                        {
                            title: "Keunikan Roojai", 
                            desc: "Pengecualian lebih sedikit dibandingkan dengan asuransi tradisional."
                        },
                        {
                            title: "Pembayaran Setelah Didiagnosis", 
                            desc: "Kamu akan menerima uang pertanggungan secara penuh jika memenuhi ketentuan berikut: dikonfirmasi mengidap gagal ginjal stadium akhir pada kedua ginjal oleh dokter spesialis yang mengharuskan melakukan cuci darah secara berkala, atau transplantasi ginjal."
                        },
                        {
                            title: "Disclaimer", 
                            desc: "Untuk syarat dan ketentuan selengkapnya, mohon mengacu pada polis asuransi."
                        },
                    ]
                }
                if(this.selector == 'dengue'){
                    idIllness = ["Malaria", "Zika", "Demam Kuning", "Demam Berdarah Dengue", "Chikungunya"]
                    enIllness = ["Malaria", "Zika", "Yellow Fever", "Dengue Haemorrhagic Fever", "Chikungunya"]
                    enBenefits = [
                        {
                            title: "Covers a wide range of cases ", 
                            desc: "Malaria, zika, yellow fever, dengue, chikungunya along with blood transfusion arising from these conditions, if confirmed by a physician and laboratory  blood test. "
                        },
                        {
                            title: "Roojai Uniqueness ", 
                            desc: "Very limited exclusions versus traditional insurance."
                        },
                        {
                            title: "Cashless Treatment", 
                            desc: "We provide facility of cashless medical payment in over 2.000 hospitals around Indonesia, which means you do not have to pay in advance and seek reimbursement. You will be covered on confirmed diagnosis with relevant medical treatment. "
                        },
                        {
                            title: "Terms", 
                            desc: "Do not cover outpatient treatment; only for hospital stay over at least 24 hours."
                        },
                        {
                            title: "Disclaimer", 
                            desc: "For complete terms and conditions, please refer to the insurance policy."
                        },
                    ]
                    idBenefits = [
                        {
                            title: "Mencakup berbagai kasus penyakit ", 
                            desc: "Malaria, zika, demam kuning, demam berdarah, chikungunya serta transfusi darah yang timbul dari kondisi-kondisi tersebut, jika dikonfirmasi oleh dokter dan pemeriksaan laboratorium darah."
                        },
                        {
                            title: "Keunikan Roojai ", 
                            desc: "Pengecualian lebih sedikit dibandingkan dengan asuransi tradisional."
                        },
                        {
                            title: "Pengobatan secara Cashless ", 
                            desc: "Layanan pembayaran pengobatan secara cashless tersedia di lebih dari 2.000 rumah sakit di seluruh Indonesia, yang berarti kamu tidak perlu membayar biaya pengobatan dengan uang sendiri dan kemudian mengajukan reimburs. Kamu akan ditanggung untuk diagnosis yang telah dikonfirmasi dengan perawatan medis yang sesuai."
                        },
                        {
                            title: "Ketentuan", 
                            desc: "Tidak mencakup rawat jalan; hanya berlaku untuk rawat inap selama setidaknya 24 jam."
                        },
                        {
                            title: "Disclaimer", 
                            desc: "Untuk syarat dan ketentuan selengkapnya, mohon mengacu pada polis asuransi."
                        },
                    ]
                }
                // this.$i18n.t('cancer_illness_list')
                let locale = Global.quote.prefLang
                return {
                    illness: locale == 'id' ? idIllness : enIllness,
                    benefits: locale == 'id' ? idBenefits : enBenefits
                }
            },
            showScrollDesc(){
                return true;//this.selector ===  'organfailure' ? false : true;
            }
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            voucher: {
                type: Boolean,
                default: false
            },
            voucherClose: {
                type: Boolean,
                default: false
            },
            width: {
                type: String,
                default: "600px"
            },
            title: {
                type: String,
                default: ""
            },
            classModal: {
                type: String,
                default: ""
            },
            selector: {
                type: String,
                default: "cancer"
            }
        },
        methods: {
            close(){
                this.$emit("close");
            },
            scrollUp(id){
                let content = document.querySelector('#listContainer'+id)
                let scrollAmount = 0;
                let slideTimer = setInterval(function(){
                    content.scrollTop -= 5;
                    scrollAmount += 5;
                    if(scrollAmount >= 150){
                        window.clearInterval(slideTimer);
                    }
                }, 10);
            },
            scrollDown(id){
                let content = document.querySelector('#listContainer'+id)
                let scrollAmount = 0;
                let slideTimer = setInterval(function(){
                    content.scrollTop += 5;
                    scrollAmount += 5;
                    if(scrollAmount >= 150){
                        window.clearInterval(slideTimer);
                    }
                }, 15);
            },
        }
    }

</script>
<i18n>
{   
  "en": {
    closeButton: "Close",
    modalTitle: "Details",
    benefitLabel: "Benefit Details",
    cancerTitle : "List of Cancer Diseases",
    dengueTitle : "List of Mosquito-Borne Diseases",
    organfailureTitle : "List of Kidney Failure Conditions",
    cardiovascularTitle : "List of Critical Cardiovascular Conditions",
    neuroTitle : "List of Neurological Conditions",
  },
  "id": {
    closeButton: "Tutup",
    modalTitle: "Detail",
    benefitLabel: "Detail Manfaat",
    cancerTitle: "Daftar Penyakit Kanker",
    dengueTitle : "Daftar Penyakit Akibat Gigitan Nyamuk",
    organfailureTitle : "Daftar Kondisi Gagal Ginjal",
    cardiovascularTitle : "Daftar Penyakit Jantung",
    neuroTitle : "Daftar Penyakit Gangguan Saraf",
  },
}
</i18n>
<style scoped>
    .closeButton{
        display: flex;
        padding: 8px 8px;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 8px;
        flex: 1 0 0;
        flex-wrap: wrap;
        color: #FFF;
        text-align: center;
        font-family: Public Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; 
        letter-spacing: 0.28px;
        border-radius: 8px;
        background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%);
        margin: 0 16px 16px 16px;
        cursor: pointer;
    }
    .closeButton:hover{
        background: #C73E1E;
    }
    .contentOuterBox{
        padding: 16px 0 0 0;
    }
    .contentBox{
        margin: 0 16px 16px 16px;
        display: flex;
        flex-direction: column;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
    }
    .titleContentBox{
        display: flex;
        padding: 12px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background: #F7F7F7;
        color: #000;
        font-family: Public Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; 
        letter-spacing: 0.28px;
        align-items: center;
        /* height: 70px; */
        justify-content: space-between;
        cursor: pointer;
    }
    .titleContentBox:hover{
        background: #E8E8E8;
    }
    .listContainer{
        margin-bottom: 4px;
        max-height: 300px;
        overflow: auto;
        margin-right: 10px;
    }
    .listContainer2{
        overflow: auto;
    }
    .listContainer::-webkit-scrollbar{
        scrollbar-color: #F4866D #dddddd;
        width: 4px;
        height: 80%;
    }
    
    .listContainer::-webkit-scrollbar-track{
        background:#dddddd;
        -webkit-border-radius:10px;
        border-radius:10px;
        margin-top: 25px;
        margin-bottom: 25px;
        margin-right: 10px;
    }
    .listContainer::-webkit-scrollbar-thumb{
        border-radius:10px;
        background:#F4866D;
    }
    .listContent {
        display: flex;
        padding: 8px;
        align-items: flex-start;
        border-bottom: 1px solid #F7F7F7;
        color: #6F6F6F;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 0 5px;
        width: 300px;
    }
    .benefits{
        padding: 4px 8px;
        align-items: flex-start;
    }
    .benefits p{
        color: #6F6F6F;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0 5px;
    }
    .benefits li{
        color: #6F6F6F;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0 5px;
        margin-left: -20px;
    }
    .benefits li ol li{
        color: #6F6F6F;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0 5px;
        margin-left: -28px;
    }
    .benefits h6{
        align-items: flex-start;
        color: #6F6F6F;
        font-family: Noto Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        margin: 0 5px;
    }
    .modal {
        display: block;
    }
    .modal-dialog-scrollable {
      display: -ms-flexbox;
      display: flex;
      max-height: calc(100% - 1rem);
    }
    
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 1rem);
      overflow: hidden;
    }
    
    .modal-dialog-scrollable .modal-content {
      overflow-y: auto;
      /* max-height: 512px !important; */
    }
    .modal-mv {
        text-align: center;
        padding: 0!important;
        background-color: rgba(0,0,0,0.7)!important;
    }
    .modal-mv .modal-dialog {
        /* margin: 5rem auto!important; */
        /* top: 10%; */
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        max-width: 300px !important;
    }
    .modal-mv .modal-dialog.garage{
        top: 4% !important;
    }
    .modal-mv .modal-dialog.voucher{
        top: 1% !important;
    }
    .modal-mv .modal-content.voucher {
        border-radius: 8px!important;
        background-image: url("../../../../public/images/motor-vehicle/bg-voucher.svg"), linear-gradient(180deg, #00539A 0%, #003A6D 100%);
        background-position: top center;
        background-repeat: no-repeat;
        position: static;
    }
    
    .modal-mv .modal-content {
        border-radius: 8px!important;
    }
    
    .modal-mv .modal-content .modal-title{
        margin:16px 16px 0px 16px;
    }
    .modal-mv .modal-content.voucher .modal-title .title-modal{
        font-weight: 800;
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        width: 100%;
        max-width: none !important;
    }
    .modal-mv .modal-content .modal-title .title-modal{
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: #000;
        max-width: 228px;
    }
    
    .modal-mv .modal-content .modal-title .btn-close-modal{
        padding: 0px;
        width: 28px;
        height: 28px;
        background-color: #F4F9FF;
        background-image: url("../../../../public/images/motor-vehicle/btnx.svg");
        background-repeat: no-repeat, no-repeat;
        background-position: top 7px left 7px;
        border-radius: 48px;
        margin-left: auto;
        color: #A8A8A8;
    }
    
    .modal-mv .modal-content .modal-title .btn-close-modal.voucher{
        opacity: 1;
        position: absolute;
        top: -10px;
        z-index: 1760;
        right: 2px;
    }
    
    .modal-mv .modal-content .btn-close-modal:hover{
        background-image: url("../../../../public/images/motor-vehicle/btnx2.svg");
        background-repeat: no-repeat, no-repeat;
        background-position: top 7px left 7px;
        background-color: #D6E6FF;
    }  
    .slideIn {
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .slideOut {
        -webkit-animation-name: slideInDown;
        animation-name: slideInDown;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .slideRight {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .slideLeft {
        -webkit-animation-name: slideInLeft;
        animation-name: slideInLeft;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .shake {
        -webkit-animation-name: shake;
        animation-name: shake;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    
    @-webkit-keyframes slideInUp {
        0% {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
    @keyframes slideInUp {
        0% {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
    
    @-webkit-keyframes slideInDown {
        from {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            visibility: visible;
        }
        to {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }
    }
    @keyframes slideInDown {
        from {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            visibility: visible;
        }
        to {
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
        }
    }
    
    
    @-webkit-keyframes slideInRight {
        from { 
            -webkit-transform: translateX(100%); 
            transform: translateX(100%);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    @keyframes slideInRight {
        from { 
            -webkit-transform: translateX(100%); 
            transform: translateX(100%);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    
    @-webkit-keyframes slideInLeft {
        from { 
            -webkit-transform: translateX(0); 
            transform: translateX(0);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
    }
    @keyframes slideInLeft {
        from { 
            -webkit-transform: translateX(0); 
            transform: translateX(0);
            visibility: visible;
        }
    
        to {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }
    }
    
    @-webkit-keyframes shake {
        0%, 100% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        10%, 30%, 50%, 70%, 90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }
        20%, 40%, 60%, 80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    }
    @keyframes shake {
        0%, 100% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        10%, 30%, 50%, 70%, 90% {
            -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
        }
        20%, 40%, 60%, 80% {
            -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
        }
    } 
    
    @media screen and (max-width: 480px) {
        .modal-dialog {
            max-width: 93%;
        }
    }

    .illLogo{
        width: 24px;
        height: 24px;
    }

    .center-modal{
        display: flex; 
        justify-content: center; 
        align-items: center;
    }
    </style>