<template>
<div class="infoCard" v-if="isShow">
    <div class="content-left">
        <p></p>
        <div class="image-left">
            <img class="img-premi" src="/images/icon-jojo.png" width="56" height="56" />
        </div>
    </div>
    <div class="content-center align-items-center">
        <div class="info-text">
            {{ text }}
        </div>
    </div>
    <div class="content-right" @click="isShow = false">
        <div class="bg-svg">
            <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 15.1274L15 9.12744M9 9.12744L15 15.1274" stroke="#F0FAFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <rect x="0.5" y="0.627441" width="23" height="23" rx="11.5" stroke="#F0FAFF"/>
            </svg>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: "InfoCard",
    data(){
        return {
            isShow: true
        }
    },
    props: {
        text: {
            type: String,
            default: ""
        },
        arrowMargin:{
            type: String,
            default: '-18px'
        }
    },
}
</script>
<style scoped>
.infoCard {
    display: flex;
    background: #00539A;
    padding: 8px 16px;
    border-radius: 8px;
    margin-top: 14px;
}

.infoCard .content-left p::before{
    content:"";
    position: absolute;
    height: 0px;
    width: 0px;
    top: -30px;
    left: -5px; 
    border-width: 15px;
    border-color: transparent #00539A transparent transparent;
    border-style: solid;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.infoCard .content-left .image-left.arrow::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    display: block;
    content: '';
    width: 14px;
    height: 14px;
    border: 1px solid #00539A;
    position: absolute;
    border-top: 0;
    border-left: 0;
    left: 6px;
    background: #00539A; 
    top: v-bind('arrowMargin');
}
.infoCard .content-center {
    width: 84%;
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
}
.infoCard .content-right {
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.bg-svg{
    width: 24px;
    height: 24px;
    background-color: #00539A;
    border-radius: 50%;
}
.bg-svg:hover{
    width: 24px;
    height: 24px;
    background-color: #E2EFF9;
}
.infoCard .content-right svg:hover path {
    stroke: #00539A;
}
.infoCard .content-right svg:hover rect {
    stroke: #E2EFF9;
}
.infoCard .content-left {
    display: flex;
    align-items: center;
    position: relative;
}
.infoCard .content-left .image-left {
    position: relative;
    text-align: left;
}
.infoCard .content-center .info-text {
    color: #fff;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.24px;
    text-align: left;
}

@media screen and (max-width: 480px) {
    
    
}
</style>